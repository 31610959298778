@import "./ax-variables-features.less";

// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
//
//  UI 3.0 COMMON VARIABLES ONLY
//  The `ax-` prefix is temporary to help differentiate "new" and "old" variables.
//
//  Check out the Axonify UI Kit for visual examples and usage details:
//  https://www.figma.com/file/mAGiAo8UwCUfdpW9SNdswW/UIKit?node-id=113%3A1674
//
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -

// - - - - - - - - - - - - - - - - -
//   Typography
// - - - - - - - - - - - - - - - - -
/**
* @tokens UIKit:FontFamily
* @presenter FontFamily
*/
// Font family
@ax-font-family:                      'Roboto', sans-serif;
@ax-font-family-MY:                   'Padauk', @ax-font-family;

/**
* @tokens UIKit:FontSize
* @presenter FontSize
*/
@ax-font-size-xl:                     2.4rem;
@ax-font-size-l:                      2.1rem;
@ax-font-size-m:                      1.8rem;
@ax-font-size-s:                      1.6rem;
@ax-font-size-xs:                     1.4rem;

@ax-font-size-html:                   0.625em; // 10px equivalent (calculated from 16px browser default)
@ax-font-size-html-m:                 0.688em; // Language legibility (minimum font-size from 14px -> 16px)
@ax-font-size-html-l:                 0.750em; // Language legibility (minimum font-size from 14px -> 17px)
@ax-font-size-html-xl:                0.813em; // Language legibility (minimum font-size from 14px -> 18px)
@ax-font-size-html-ie-edge:           0.64em;  // IE/Edge - 10px equivalent (for decimal rounding issues)
@ax-font-size-body:                   1.6rem;

/**
* @tokens UIKit:LineHeight
* @presenter LineHeight
*/
@ax-line-height-xl:                   3.2rem;
@ax-line-height-l:                    3.2rem;
@ax-line-height-m:                    2.8rem;
@ax-line-height-s:                    2.4rem;
@ax-line-height-xs:                   1.8rem;

/**
* @tokens UIKit:FontWeight
* @presenter FontWeight
*/
@ax-font-weight-regular:              normal; // 400
@ax-font-weight-medium:               500;
@ax-font-weight-bold:                 bold;   // 700
/**
* @tokens-end
*/

// - - - - - - - - - - - - - - - - -
//   Colors
// - - - - - - - - - - - - - - - - -
/**
* @tokens UIKit:Misc
* @presenter Color
*/
@ax-color-app-background:             #FBFBFB;
@ax-color-icon-disabled:              @ax-color-grey-40;
@ax-color-icon-green-a11y:            @ax-color-training;
@ax-color-grey-smoke:                 #EFEFEF;

@ax-color-cta-action-background:      #029B55;
/**
* @tokens UIKit:TextColor
* @presenter Color
*/
@ax-color-text:                       @ax-color-grey-80;
@ax-color-text-secondary:             @ax-color-grey-60;


/**
* @tokens UIKit:Color:Base
* @presenter Color
*/
@ax-color-white:                      #FFFFFF;
@ax-color-black:                      #000000;


/**
* @tokens UIKit:Color:Neutrals
* @presenter Color
*/
@ax-color-grey-10:                    #FAFAFA;
@ax-color-grey-20:                    #F2F2F3;
@ax-color-grey-30:                    #D8D8DA;
@ax-color-grey-40:                    #BBBBBE;
@ax-color-grey-50:                    #8E8E93; // icons/borders only
@ax-color-grey-60:                    #727278;
@ax-color-grey-70:                    #545459;
@ax-color-grey-80:                    #343437;
/**
* @tokens-end
*/

/**
* @tokens UIKit:Color:Blue
* @presenter Color
*/
// Primary
@ax-color-blue-40:                    #8DCDFB;
@ax-color-blue-60:                    #1767DD;
@ax-color-blue-80:                    #0B4CAF;
@ax-color-blue-100:                   #073484;

/**
* @tokens UIKit:Color:Green
* @presenter Color
*/
// Secondary / Successes / Enabled
@ax-color-green-40:                   #98E29E;
@ax-color-green-60:                   #037F44;
@ax-color-green-80:                   #035E42;
@ax-color-green-100:                  #02442E;

/**
* @tokens UIKit:Color:Orange
* @presenter Color
*/
// Warning
@ax-color-orange-40:                  #FFB74D;
@ax-color-orange-60:                  #FB8C00;
@ax-color-orange-80:                  #EF6C00;
@ax-color-orange-100:                 #CC4800;

/**
* @tokens UIKit:Color:Red
* @presenter Color
*/
// Caution / Failure
@ax-color-red-40:                     #F7879E;
@ax-color-red-60:                     #BA0C2F;
@ax-color-red-80:                     #900924;
@ax-color-red-100:                    #600618;
/**
* @tokens-end
*/

/**
* @tokens UIKit:Color:Semantic
* @presenter Color
*/
@ax-color-semantic-informational:     @ax-color-blue-60;
@ax-color-semantic-positive:          @ax-color-green-60;
@ax-color-semantic-warning:           @ax-color-orange-60;
@ax-color-semantic-negative:          @ax-color-red-60;
/**
* @tokens-end
*/

/**
* @tokens UIKit:Color:App Zone Menu Colors
* @presenter Color
*/
@ax-color-training-zone:                   #436282;
@ax-color-manager-zone:                    #c02534;
@ax-color-admin-zone:                      #437e54;
/**
* @tokens-end
*/

// - - - - - - - - - - - - - - - - -
//   Spacing
// - - - - - - - - - - - - - - - - -
/**
* @tokens UIKit:Spacing
* @presenter Spacing
*/
// Linear scale
@ax-spacing-xxl:                      4.0rem;
@ax-spacing-xl:                       3.2rem;
@ax-spacing-l:                        2.4rem;
@ax-spacing-m:                        1.6rem;
@ax-spacing-s:                        0.8rem;

// Half steps
@ax-spacing-xs:                       0.4rem;
@ax-spacing-xxs:                      0.2rem;

@ax-gutter-grid:                      @ax-spacing-l;
@ax-gutter-page:                      @ax-spacing-m;

@ax-page-width-timeline:              74.4rem;
@ax-page-width-s:                     100%;
@ax-page-width-l:                     1120px;
/**
* @tokens-end
*/

// - - - - - - - - - - - - - - - - -
//   Misc
// - - - - - - - - - - - - - - - - -
/**
* @tokens UIKit:BorderRadius
* @presenter BorderRadius
*/
@ax-border-radius-s:                  0.3rem;
@ax-border-radius-m:                  0.5rem;
@ax-border-radius-l:                  0.7rem;
@ax-border-radius-xl:                 0.9rem;
/**
* @tokens-end
*/

/**
* @tokens UIKit:Transitions
* @presenter Easing
*/
@ax-transition-speed:                 0.16s;
@ax-transition-ease:                  ease-in-out;
/**
* @tokens-end
*/

/**
* @tokens UIKit:Opacity
* @presenter Opacity
*/
@ax-opacity-xl:                       0.32;
@ax-opacity-l:                        0.24;
@ax-opacity-m:                        0.16;
@ax-opacity-s:                        0.08;
/**
* @tokens-end
*/

// Header focus
@ax-color-header-focus: #59595933;
@ax-box-shadow-header-focus: inset 0 0 .2rem .2rem @ax-color-blue-60;

// Box shadows
@ax-box-shadow-l: 0 1rem 2.4rem 0 rgba(@ax-color-grey-70, @ax-opacity-l), 0 .4rem .8rem 0 rgba(@ax-color-grey-70, @ax-opacity-m);
@ax-box-shadow-m: 0 .1rem .6rem 0 rgba(@ax-color-grey-70, @ax-opacity-l), 0 .4rem .8rem -.2rem rgba(@ax-color-grey-70, @ax-opacity-m);
@ax-box-shadow-s: 0 0 0 .1rem rgba(@ax-color-grey-80, @ax-opacity-s), 0 .1rem .2rem 0 rgba(@ax-color-grey-80, @ax-opacity-l), 0 .2rem .4rem 0 rgba(@ax-color-grey-80, @ax-opacity-l);

// Breakpoints
@ax-breakpoint-desktop-min:           992px;
@ax-breakpoint-desktop-max:           @ax-page-width-l;
@ax-breakpoint-tablet-min:            640px;
@ax-breakpoint-tablet-max:            @ax-breakpoint-desktop-min - 1px;
@ax-breakpoint-mobile-min:            1px;
@ax-breakpoint-mobile-max:            @ax-breakpoint-tablet-min - 1px;

@ax-breakpoint-prefix-m:              ~'m';
@ax-breakpoint-prefix-l :             ~'l';
@ax-breakpoint-prefix-optional-m:     ~'@{ax-breakpoint-prefix-m}-';
@ax-breakpoint-prefix-optional-l:     ~'@{ax-breakpoint-prefix-l}-';

// Input sizing
@ax-input-max-width-m:                35rem;
@ax-input-number-min-width:           9.3rem; // Input type number

// Device Height Breakpoints
@ax-breakpoint-height-max-xxs:        480px; // iPhone 4, small Kronos widget
@ax-breakpoint-height-max-xs:         667px; // iPhone 5/6/7/8
@ax-breakpoint-height-max-s:          736px; // Zebra, iPhone 6+/7+/8+

// CSS variables for use in React
:root {
  // - - - - - - - - - - - - - - - - -
  //   Borders
  // - - - - - - - - - - - - - - - - -
  --ax-border-radius-s : @ax-border-radius-s;
  --ax-border-radius-m : @ax-border-radius-m;
  --ax-border-radius-l : @ax-border-radius-l;
  --ax-border-radius-xl: @ax-border-radius-xl;

    // - - - - - - - - - - - - - - - - -
    //   Box Shadows
    // - - - - - - - - - - - - - - - - -

  --ax-box-shadow-s: @ax-box-shadow-s;
  --ax-box-shadow-m: @ax-box-shadow-m;
  --ax-box-shadow-l: @ax-box-shadow-l;

    // - - - - - - - - - - - - - - - - -
    //   Breakpoints
    // - - - - - - - - - - - - - - - - -

  --ax-breakpoint-min-s: @ax-breakpoint-mobile-min;
  --ax-breakpoint-min-m: @ax-breakpoint-tablet-min;
  --ax-breakpoint-min-l: @ax-breakpoint-desktop-min;

    // - - - - - - - - - - - - - - - - -
    //   Colors
    // - - - - - - - - - - - - - - - - -

    // Neutrals
  --ax-color-black                : @ax-color-black;
  --ax-color-white                : @ax-color-white;
  --ax-color-app-background       : @ax-color-app-background;
  --ax-color-cta-action-background: @ax-color-cta-action-background;

  --ax-color-grey-10: @ax-color-grey-10;
  --ax-color-grey-20: @ax-color-grey-20;
  --ax-color-grey-30: @ax-color-grey-30;
  --ax-color-grey-40: @ax-color-grey-40;
  --ax-color-grey-50: @ax-color-grey-50;
  --ax-color-grey-60: @ax-color-grey-60;
  --ax-color-grey-70: @ax-color-grey-70;
  --ax-color-grey-80: @ax-color-grey-80;

    // Primary
  --ax-color-blue-40 : @ax-color-blue-40;
  --ax-color-blue-60 : @ax-color-blue-60;
  --ax-color-blue-80 : @ax-color-blue-80;
  --ax-color-blue-100: @ax-color-blue-100;

    // Secondary / Successes / Enabled
  --ax-color-green-40 : @ax-color-green-40;
  --ax-color-green-60 : @ax-color-green-60;
  --ax-color-green-80 : @ax-color-green-80;
  --ax-color-green-100: @ax-color-green-100;

    // Warning
  --ax-color-orange-40 : @ax-color-orange-40;
  --ax-color-orange-60 : @ax-color-orange-60;
  --ax-color-orange-80 : @ax-color-orange-80;
  --ax-color-orange-100: @ax-color-orange-100;

    // Caution / Failure
  --ax-color-red-40 : @ax-color-red-40;
  --ax-color-red-60 : @ax-color-red-60;
  --ax-color-red-80 : @ax-color-red-80;
  --ax-color-red-100: @ax-color-red-100;

    // Semantic
  --ax-color-semantic-informational: @ax-color-semantic-informational;
  --ax-color-semantic-positive     : @ax-color-semantic-positive;
  --ax-color-semantic-warning      : @ax-color-semantic-warning;
  --ax-color-semantic-negative     : @ax-color-semantic-negative;

    // Typography
  --ax-color-text          : @ax-color-text;
  --ax-color-text-secondary: @ax-color-text-secondary;

    // Misc
  --ax-color-grey-smoke: @ax-color-grey-smoke;
  --ax-color-grey-accent: @accent-grey;

    // App Zones
  --ax-color-training-zone: @ax-color-training-zone;
  --ax-color-manager-zone : @ax-color-manager-zone;
  --ax-color-admin-zone   : @ax-color-admin-zone;

    // Header focus
  --ax-color-header-focus     : @ax-color-header-focus;
  --ax-box-shadow-header-focus: @ax-box-shadow-header-focus;

    // - - - - - - - - - - - - - - - - -
    //   Typography
    // - - - - - - - - - - - - - - - - -

  --ax-font-size-xs: @ax-font-size-xs;
  --ax-font-size-s : @ax-font-size-s;
  --ax-font-size-m : @ax-font-size-m;
  --ax-font-size-l : @ax-font-size-l;
  --ax-font-size-xl: @ax-font-size-xl;

  --ax-font-weight-bold   : @ax-font-weight-bold;
  --ax-font-weight-medium : @ax-font-weight-medium;
  --ax-font-weight-regular: @ax-font-weight-regular;

  --ax-line-height-xs: @ax-line-height-xs;
  --ax-line-height-s : @ax-line-height-s;
  --ax-line-height-m : @ax-line-height-m;
  --ax-line-height-l : @ax-line-height-l;
  --ax-line-height-xl: @ax-line-height-xl;

    // - - - - - - - - - - - - - - - - -
    //   Spacing
    // - - - - - - - - - - - - - - - - -
  --ax-spacing-xxs: @ax-spacing-xxs;
  --ax-spacing-xs : @ax-spacing-xs;
  --ax-spacing-s  : @ax-spacing-s;
  --ax-spacing-m  : @ax-spacing-m;
  --ax-spacing-l  : @ax-spacing-l;
  --ax-spacing-xl : @ax-spacing-xl;
  --ax-spacing-xxl: @ax-spacing-xxl;

    // - - - - - - - - - - - - - - - - -
    //   Opacity
    // - - - - - - - - - - - - - - - - -
  --ax-opacity-xl: @ax-opacity-xl;
  --ax-opacity-l : @ax-opacity-l;
  --ax-opacity-m : @ax-opacity-m;
  --ax-opacity-s : @ax-opacity-s;

    // - - - - - - - - - - - - - - - - -
    //   Misc
    // - - - - - - - - - - - - - - - - -
  --ax-page-width-timeline: @ax-page-width-timeline;
  --ax-page-width-s       : @ax-page-width-s;
  --ax-page-width-l       : @ax-page-width-l;

    // - - - - - - - - - - - - - - - - -
    //   Transition
    // - - - - - - - - - - - - - - - - -
  --ax-transition-speed: @ax-transition-speed;
  --ax-transition-ease : @ax-transition-ease;

  // - - - - - - - - - - - - - - - - -
  //   Branding (in case Branding API is not available)
  // - - - - - - - - - - - - - - - - -
  --ax-link-color:                     var(--ax-color-blue-60); // defined in axFormCommon.html
  --axButton-branded-background:       var(--ax-color-blue-60); // defined in axButtonCommon.html
  --axButton-branded-background-hover: var(--ax-color-blue-80); // defined in axButtonCommon.html
  --axButton-branded-color:            var(--ax-color-white); // defined in axButtonCommon.html
}
